.collapsible {
  position: relative;
  height: 0;
  transition: padding .6s ease-out;
  width: 100%;
  overflow: hidden;
}

.collapsible.is-closed {
  padding: 0!important;
}