:root {
  --fill-opacity: 0;
}

$color-white: #ffffff;
$color-black: #000000;
$color-bg: #e5e5e5;
$color-blue: #00529b;
$color-text: #4a4c50;

$font-text: Arial, sans-serif;

/**
** Breakpoints
**/
// min or max
$media-direction: min; // min / max — default is min (mobile first)

// Breakpoints
// A Sass Map for all Breakpoints you need. But keep it simple! Just add Sizes that you need several times.
// Here are my basic set. I change the values a bit from project to project
$breakpoints: (
        's' : 800px,
        'm' : 1600px
);

// =========
// content width
// =========
$content-max-width: 750px;
$content-max-width-s: 1125px;
$content-max-width-m: 1920px;
