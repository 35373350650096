body.pop-up-is-open {
  overflow: hidden;
}

.pop-up {

  z-index: 999;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.35s ease-in-out;

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
  }
  &__inner {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    background: $color-bg;
    @include breakpoint('s') {
      width: 60vw;
    }
    @include breakpoint('m') {
      width: 70vw;
    }

  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    background: $color-blue;
    color: $color-white;
    padding: 10px 30px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3em;
  }

  &__close {
    position: relative;
    display: block;
    width: 20px;
    height: 3px;
    cursor: pointer;

    &:before, &:after {
      content: '';
      transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
      background-color: $color-bg;
      display: block;
      position: absolute;
      width: 20px;
      height: 3px;
      left: 0;
    }

    &:before {
      transform-origin: 50% 0;
      top: -6px;
      z-index: 10;
    }

    &:after {
      transform-origin: 50% 100%;
      top: 6px;
    }
    &:before {
      transform: translateY(7px) translateX(1px) rotate(45deg);
    }

    &:after {
      transform: translateY(-6px) translateX(1px) rotate(-45deg);
    }
  }

  &__content{
    padding: 10px 30px;
    background: $color-bg;
  }

  &.is-closed {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}