.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $content-max-width;
}

.img-container {
  position: relative;
  width: 100%;

  &.collapsible {
    background-color: $color-bg;
  }

  .img, .overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;

    svg {
      height: 100%;
    }
  }

  .overlay {
    z-index: 10;
  }

}