.mobile {
  display: flex;
}

.tablet {
  display: none;
}

.desktop {
  display: none;
}

// tablet
@include breakpoint('s') {
  .container {
    max-width: $content-max-width-s;
  }

  .mobile {
    display: none;
  }

  .tablet {
    display: flex;
  }
}

// desktop
@include breakpoint('m') {
  .container {
    max-width: $content-max-width-m;
  }

  .tablet {
    display: none;
  }

  .desktop {
    display: flex;
  }
}