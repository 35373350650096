// $breakpoint = die Größe die ihr braucht.
// $direction: $media-direction = das was ihr in den Settings eingestellt habt.
// src: https://davidhellmann.com/blog/development/sass-breakpoint-mixin

@mixin breakpoint($breakpoint,$direction: $media-direction) {

  // Es wird gecheckt ob der Wert in der Sass Map vorhanden ist
  @if map-has-key($breakpoints, $breakpoint) {

    // Ausgabe
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }

    // Wenn ihr oben einen Wert eingetragen habt wird hier gecheckt ob ihr einen Einheit angehängt habt. Falls nicht wird px genutzt.
  @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;

    // Ausgabe
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }

  @else {
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }

}
