.confirm-container {

  &__content {
    position: absolute;
    bottom: 0;
    padding: 10px 15px;
  }

  &__description {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .button {
    padding: 4px 12px;
    font-size: 14px;
  }

  &__iframe-container {
    display: none;
  }

  &.show-iframe {
    .confirm-container__iframe-container {
      display: block;
    }
    .confirm-container__content {
      display: none;
    }
  }
}

@media only screen and (max-width: 430px) {
  .confirm-container {

    &__content {
      padding: 6px 12px;
    }

    &__description {
      font-size: 14px;
      line-height: 20px;
    }

  }
}

@media only screen and (max-width: 385px) {
  .confirm-container {

    &__content {
      padding: 5px 10px;
    }

    &__description {
      font-size: 13px;
      line-height: 18px;
    }

  }
}
