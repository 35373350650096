img, picture {
  height: auto;
  max-width: 100%;
  width: 100%;
  display: block;
}

svg {
  height: auto;
  width: 100%;
}

svg rect {
  cursor: pointer;
  fill-opacity: var(--fill-opacity);
}