@import "../../../global/scss/vendor/reset-css";
@import "../../../global/scss/variables";
@import "../../../global/scss/mixins/breakpoint";
@import "../../../global/scss/mixins/browser-targeting";


// img sizes
.desktop {
  .img-1 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-2 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-3 {
    // w: 4000px, h:4500px
    padding-top: 112.50%;
  }

  .img-4 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-5 {
    // w: 3998px, h:4500px
    padding-top: 112.55%;
  }

  .img-6 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-7 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-8 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-9 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-10 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }
}

.tablet, .mobile {
  .img-1 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-2 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-3 {
    // w:2344px, h:6042px
    padding-top: 257.76%;
  }

  .img-4 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-5 {
    // w: 2344px, h:9792px
    padding-top: 417.74%;
  }

  .img-6 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-7 {
    // w: 2344px, h:7917px
    padding-top: 337.75%;
  }

  .img-8 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-9 {
    // w: 2344px, h:7917px
    padding-top: 337.75%;
  }

  .img-10 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }
}

// videos
.desktop {
  .img-3 {
    .video {
      top: 33.2%;
      left: 36.2%;
      width: 24.6%;
      height: 11.6%;
    }
  }
}

.tablet {
  .img-3 {
    .video {
      top: 37.8%;
      left: 51.5%;
      width: 42%;
      height: 6.15%;
    }
  }
}


.mobile {
  .img-3 {
    .video {
      top: 36%;
      left: 31px;
      width: calc(100% - 50px);
      background: #fff;
      height: 144px;

      @include breakpoint(414) {
        width: calc(100% - 57px);
      }
      @include breakpoint(501) {
        top: 37%;
        height: 8%;
      }
      @include breakpoint(600) {
        width: 43%;
        left: 51%;
      }
      @include breakpoint(748) {
        top: 37.8%;
        height: 6.2%;
      }

    }

    .confirm-container__content {
      background: #fff;
    }
  }
}

@import "../../../global/scss/global";
@import "../../../global/scss/video";
@import "../../../global/scss/confirm-container";
@import "../../../global/scss/button";
@import "../../../global/scss/link";

//@import "../../../global/scss/debug";